/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */




 .container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  height: 56px;
  background-color: #f4f4f4;
  text-align: center;
  padding:0px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.body {
  /* padding-top:56px; */
  position: fixed;
  top: 56px;

  flex: 1;
  display: flex;
  background-color: red;

  height: calc(100vh - 56px); /* Chiều cao trừ đi header */
}

.left {
  position: fixed;
  top: 56px; /* Bên dưới header */
  left: 0;
  width: 200px;
  height: calc(100vh - 56px); /* Chiều cao trừ đi header */
  background-color:  white;
  /* padding: 10px; */
  /* box-sizing: border-box; */
}

.center {
  flex: 1;
  /* overflow-y: auto; Thêm cuộn nếu nội dung dài */
  background-color:  white;
  /* padding: 20px; */
  /* box-sizing: border-box; */
  position: fixed;
  top: 56px; /* Bên dưới header */
  left: 200px; /* Bên phải thẻ left */
  width: calc(100% - 200px); /* Chiều rộng còn lại */
  height: calc(100% - 56px); /* Chiều cao trừ header */
  /* padding: 10px; */
  box-sizing: border-box;
}

/* Nội dung cuộn bên trong center */
.center-content {
  height: 100%; /* Chiều cao full của khung */
  overflow-y: auto; /* Thêm cuộn cho nội dung dài */
  background-color: white;
  padding: 10px;
  box-sizing: border-box;
}


.right {
  width: 0px;
  /* background-color: #ffccc7; */
  /* padding: 10px; */
  text-align: center;
}

.footer {
  height: 0%;
  /* background-color: #d3f261; */
  text-align: center;
  /* padding: 10px; */
  font-size: 1rem;
  font-weight: bold;
}
